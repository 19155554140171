import React from 'react';
import { graphql, Link } from 'gatsby';
import Image from 'gatsby-image';
import SEO from '../components/seo';
import Layout from '../components/layout';

import PageContent from '../components/paragraphs/pageContent';


const Headings = ({
  title, icon, paragraphs, depliantOn,
}) => {
  const logoStyle = {
    objectFit: 'cover',
    width: '100%',
    height: 'auto',
  };
  const hStyle = {
    fontSize: '1.6rem',
    fontWeight: 'bold',
  };
  return (
    <div className="row mt-3 ">
      <div className="order-first p-1 container">
        <div className="row">
          {' '}
          <div className="col-4 ">
            <Image
              style={logoStyle}
              className="rounded "
              fluid={icon.childImageSharp.fluid}
              alt={title}
            />
          </div>
          <div className="col-8 align-self-center p-1">
            <h1 style={hStyle}>{title}</h1>
          </div>
        </div>
      </div>
    </div>
  );
};


export default function Template({ data, pageContext }) {
  const { site, page, artistes } = data;

  const paragraphs = [
    {
      key: 'lateliersaintfargeau',
      name: page.lateliersaintfargeau.nom,
      value: page.lateliersaintfargeau,
      color: page.lateliersaintfargeau.couleur.valeur,
      images: page.lateliersaintfargeau.images,
    },
    {
      key: 'objectifs_de_lassociation_chap',
      name: page.objectifs_de_lassociation_chap.nom,
      value: page.objectifs_de_lassociation_chap,
      color: page.objectifs_de_lassociation_chap.couleur.valeur,
      images: page.objectifs_de_lassociation_chap.images,
    },
    {
      key: 'les_intervenants',
      name: page.les_intervenants.nom,
      value: page.les_intervenants,
      color: page.les_intervenants.couleur.valeur,
    },
  ];
  const { siteUrl } = site.siteMetadata;
  const ArtisteCards = artistes.nodes.map((item, index) => {
    if (!item.image_presentation) {
      return <div key={`ar_${index}`} />;
    }

    const normalizedName = item.nom
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .replace(/ /g, '-')
      .toLowerCase();
    item.link = `${siteUrl}/artistes/${normalizedName}`;

    const cStyle = {
      objectFit: 'cover',
      width: '100%',
      height: '150px',
    };
    const dStyle = {
      boxShadow: '1px 3px 2px #888888',
      margin: '5px',
    };
    const pStyle = {
      color: 'black',
      width: '150px',
    };

    return (
      <div style={dStyle} className="card mb-2 box-shadow" key={`ka_${index}`}>
        <a href={item.link}>
          <Image
            style={cStyle}
            className="card-img-top"
            fluid={item.image_presentation.childImageSharp.fluid}
            alt={item.nom}
          />
        </a>
        <div className="card-body pl-1 pr-1">
          <a href={item.link}>
            {' '}
            <p style={pStyle} className="card-text text-center">
              {' '}
              {item.nom}
              {' '}
            </p>
          </a>
        </div>
      </div>
    );
  });

  return (
    <Layout title={`${site.siteMetadata.title} - ${page.nom}`} buildTime={pageContext.buildTime}>
      <SEO meta={page.meta} />
      <Headings
        title={page.nom}
        icon={page.icon}
        paragraphs={paragraphs}
      />
      <PageContent paragraphs={paragraphs} />
      <div style={{ width: '100%' }} className="mb-2 mt-2 row d-flex p-1">
        {ArtisteCards}
      </div>
    </Layout>
  );
}

export const pageQuery = graphql`
  query presentationQuery {
    site: site {
      siteMetadata {
        title
        description
        author
        siteUrl
      }
    }
    page: strapiPresentation {
      nom
      image_presentation {
        publicURL
        childImageSharp {
          fluid(maxWidth: 600, quality: 100) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      icon {
        publicURL
        childImageSharp {
          fluid(maxWidth: 600, quality: 100) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      lateliersaintfargeau {
        contenu
        nom
        images {
          image {
            publicURL
            childImageSharp {
              fluid(maxWidth: 600, quality: 100) {
                ...GatsbyImageSharpFluid
                ...GatsbyImageSharpFluidLimitPresentationSize
              }
            }
          }
          nom
        }
        couleur {
          nom
          valeur
        }
      }
      les_intervenants {
        contenu
        nom
        couleur {
          id
          nom
          valeur
        }
      }
      objectifs_de_lassociation_chap {
        contenu
        images {
          image {
            publicURL
            childImageSharp {
              fluid(maxWidth: 600, quality: 100) {
                ...GatsbyImageSharpFluid
                ...GatsbyImageSharpFluidLimitPresentationSize
              }
            }
          }
          nom
        }
        nom
        couleur {
          nom
          valeur
        }
      }
      meta {
        description
        title
        url
        image {
          publicURL
          childImageSharp {
            fixed(width: 200) {
            width
            height
            src
          }
          }
        }
      }
    }
    artistes: allStrapiArtistes(filter: { actif: { eq: true } }) {
      nodes {
        id
        image_presentation {
          publicURL
          childImageSharp {
            fluid(maxWidth: 600, quality: 100) {
              ...GatsbyImageSharpFluid
              ...GatsbyImageSharpFluidLimitPresentationSize
            }
          }
        }
        a_propos {
          nom
          contenu
        }
        discipline
        description
        images {
          image {
            publicURL
            childImageSharp {
              fluid(maxWidth: 600, quality: 100) {
                ...GatsbyImageSharpFluid
                ...GatsbyImageSharpFluidLimitPresentationSize
              }
            }
          }
          nom
        }
        nom
        site_web
      }
    }
  }
`;
