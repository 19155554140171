import React from "react";
import Markdown from "markdown-to-jsx";
import Bullet from "../page/bullet";
import SidebarImageGallery from "../page/SidebarImageGallery";

const Paragraph = ({ name, content, anchor, color, images, children }) => {
  const paragraphStyle = {
    backgroundColor: color,
    borderColor: color,
    boxShadow: "1px 3px 2px #888888",
    marginRight: "20px",
  };
  if (images === undefined) {
    images = [];
  }
  return (
    <>
      <div className="row  ">
        <div className="col-md-8 col-md-pull-4 blog-main p-1">
        <a className="anchor" id={anchor}/>
          <button className="link-button" />

          <div className="d-flex justify-content-start  mb-3 align-items-center ">
            <Bullet color={color} />
            <div className="pageCard card-text pl-1 pt-0 ">
              <h2 className="card-text pageCard"> {name} </h2>
            </div>
          </div>
        </div>
      </div>
      <div className="row  ">
        <div className="col-md-8 col-md-pull-4 blog-main p-1">
          <div className="card  mb-3 p-3 mt-1" style={paragraphStyle}>
            {content != undefined ? <Markdown>{content}</Markdown> : <></>}
            {children}
          </div>
        </div>
        <aside className="col-md-4 col-md-push-8 blog-sidebar align-middle order-md-2 order-first mt-1 p-1">
          <SidebarImageGallery images={images} />
        </aside>
      </div>
    </>
  );
};

export default Paragraph;
