import React from 'react';
import PropTypes from 'prop-types';
import Paragraph from './paragraphWithImages';

const PageContent = ({ paragraphs }) => (
  <>
    {paragraphs.map((item) => {
      const images = item.images != null ? item.images : [];
      return (
        <div key={`k_${item.key}`}>
          <Paragraph
            name={item.value.nom}
            content={item.value.contenu}
            anchor={item.key}
            color={item.color}
            images={images}
          />
        </div>

      );
    })}
  </>
);

PageContent.propTypes = {
  paragraphs: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      color: PropTypes.string,
      value: PropTypes.shape({
        nom: PropTypes.string,
        contenu: PropTypes.string,
        couleur: PropTypes.shape({
          valeur: PropTypes.string,
        }),
      }),
    }),
  ),
};

PageContent.defaultProps = {
  paragraphs: [],
};

export default PageContent;
